<template>
  <div id="courseLessons">
    <v-container fluid class="my-12">
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <v-card class="lesson-card pa-2">
            <v-list color="transparent" rounded>
              <v-list-item
                v-for="(lesson, i) in lessons"
                :key="i"
                @click="selectElesson(i)"
              >
                <v-list-item-icon>
                  <v-icon color="primary" size="48"
                    >mdi-motion-play-outline</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <span class="d-block subtitle-2 grey--text">
                    Modül
                    {{
                      10 > lesson.rowNumber
                        ? "0" + lesson.rowNumber
                        : lesson.rowNumber
                    }}:
                  </span>
                  <v-list-item-title v-text="lesson.title" />
                </v-list-item-content>
                <v-list-item-action>
                  <v-progress-circular
                    size="32"
                    width="3"
                    :value="calcPercent(lesson.progress)"
                    color="primary"
                  >
                    <span v-if="!lesson.completed" style="font-size: 0.6rem;">{{
                      calcPercent(lesson.progress)
                    }}</span>
                    <v-icon v-if="lesson.completed" color="primary" small
                      >mdi-check</v-icon
                    >
                  </v-progress-circular>
                </v-list-item-action>
              </v-list-item>

              <v-list-item @click="selectEbook()">
                <v-list-item-icon>
                  <v-icon color="primary" size="48"
                    >mdi-file-pdf-outline</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>e-Kitap</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-progress-circular
                    size="32"
                    width="3"
                    :value="courseRegistration.progressEbook"
                    color="primary"
                  >
                    <v-icon
                      :color="
                        courseRegistration.progressEbook === 0
                          ? 'grey lighten-1'
                          : 'primary'
                      "
                      small
                      >mdi-check</v-icon
                    >
                  </v-progress-circular>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>

          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card :color="dialogBgColor" tile>
              <v-toolbar :dark="dialogDark" color="transparent" elevation="0">
                <v-btn icon :dark="dialogDark" @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ selectedItem.title }}</v-toolbar-title>
              </v-toolbar>

              <e-lesson
                v-if="selectedItem.type == 'elesson'"
                :lesson="selectedItem"
                :course="course"
                :courseRegistration="courseRegistration"
              />

              <e-book
                v-if="selectedItem.type == 'ebook'"
                :course="course"
                :courseRegistration="courseRegistration"
              />
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import EBook from "./Ebook.vue";
import ELesson from "./Elesson.vue";

export default {
  metaInfo: () => ({
    title: "Konular"
  }),
  components: {
    EBook,
    ELesson
  },
  props: {
    course: {
      type: Object,
      required: true
    },
    courseRegistration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lessons: [
        {
          id: "",
          rowNumber: 1,
          title: "",
          progress: 0,
          completed: false
        }
      ],
      panel: [0],
      dialog: false,
      dialogBgColor: "#000",
      dialogDark: true,
      selectedItem: {
        title: "",
        type: ""
      }
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  watch: {
    "course.id": async function() {
      await this.getModules();
    },
    dialog: async function(newDialog) {
      if (newDialog == false) {
        Tawk_API.showWidget(); // eslint-disable-line 

        // İlerleme durumunu güncelle
        await this.updateLessonProgress(
          this.courseRegistration.id,
          this.selectedItem.id
        );

        this.selectedItem = {};
      } else {
        Tawk_API.hideWidget(); // eslint-disable-line

        this.dialogBgColor = "#1e1e1e";
        this.dialogDark = true;
      }
    }
    // "courseRegistration.completedLessons": function() {
    //   this.lessons.forEach(lesson => {
    //     // Modülün kullanım bilgisini al
    //     const findResult = this.courseRegistration.completedLessons.find(
    //       function(obj) {
    //         if (obj.id === lesson.id) {
    //           return obj;
    //         }
    //       }
    //     );

    //     if (findResult !== undefined) {
    //       lesson.progress = findResult.progress;
    //     } else {
    //       lesson.progress = 0;
    //     }
    //   });
    // }
  },
  methods: {
    async getModules() {
      this.lessons = [];

      // Modülleri al
      await firebase
        .firestore()
        .collection("lessons")
        .where("courseId", "==", this.course.id)
        .orderBy("rowNumber")
        .get()
        .then(qs1 => {
          qs1.forEach(lesson => {
            // Modül bilgileri
            const newLesson = {
              id: lesson.id,
              rowNumber: lesson.data().rowNumber,
              title: lesson.data().title,
              progress: 0,
              completed: false
            };

            // Öğenin kullanım bilgisini al
            const findResult = this.courseRegistration.completedLessons.find(
              obj => obj.id === newLesson.id
            );

            if (findResult !== undefined) {
              newLesson.progress = findResult.progress;
              newLesson.completed = findResult.completed
                ? findResult.completed
                : false;
            }

            this.lessons.push(newLesson);
          });
        });
    },

    selectElesson(i) {
      this.selectedItem = this.lessons[i];
      this.selectedItem.type = "elesson";
      this.dialog = true;
    },

    selectEbook() {
      this.selectedItem = {
        title: `e-Kitap: ${this.course.name}`,
        type: "ebook"
      };
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    calcPercent(val) {
      return Math.ceil(val * 100);
    },

    async updateLessonProgress(courseRegId, lessonId) {
      const docSs = await firebase
        .firestore()
        .doc(`/courseRegistrations/${courseRegId}`)
        .get();

      if (docSs.exists) {
        this.courseRegistration.progress = docSs.data()?.progress;
        this.courseRegistration.progressEbook = docSs.data()?.progressEbook;
        const completedLessons = docSs.data()?.completedLessons;

        if (completedLessons) {
          const item = completedLessons.find(x => x.id == lessonId);

          console.log(item);

          if (item) {
            this.lessons.forEach(lesson => {
              if (lesson.id == lessonId) {
                lesson.progress = item.progress;
                lesson.completed = item.completed;
                return;
              }
            });
          }
        }
      }
    }
  },
  async mounted() {
    if (this.course.id) {
      await this.getModules();
    }
  }
};
</script>

<style lang="scss" scoped>
#courseLessons {
  .lesson-card {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: all ease 0.3s;
    background-color: $color6;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
