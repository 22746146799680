












import Vue from "vue";
import firebase from "@/plugins/firebase";

export default Vue.extend({
  props: {
    course: {
      type: Object,
      required: true
    },
    courseRegistration: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.setProgress(100);
  },
  computed: {
    eBookSrc() {
      return this.course.ebook;
    }
  },
  methods: {
    async setProgress(value: number) {
      try {
        const reg = firebase
          .firestore()
          .doc(`courseRegistrations/${this.courseRegistration.id}`);

        await reg.update({
          progressEbook: value
        });
      } catch (err) {
        this.$notify({
          type: "error",
          text: "Görüntüleme kaydı işlenemedi."
        });

        console.log(err);
      }
    }
  }
});
