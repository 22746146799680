












/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";

export default Vue.extend({
  props: {
    lesson: {
      type: Object,
      required: true
    },
    course: {
      type: Object,
      required: true
    },
    courseRegistration: {
      type: Object,
      required: true
    }
  },
  async mounted() {
    
    await this.setProgress(0);
  },
  computed: {
    lessonSrc() {
      return `/courses/${this.course.urlCode}/elesson-${this.lesson.rowNumber}/index.html?courseRegId=${this.courseRegistration.id}&lessonId=${this.lesson.id}`;
    }
  },
  methods: {
    async setProgress(value: number) {
      const regRef = firebase
        .firestore()
        .doc(`/courseRegistrations/${this.courseRegistration.id}`);

      const regSs = await regRef.get();

      if (regSs.exists) {
        const completedLessons = regSs.data()?.completedLessons;

        if (completedLessons) {
          // Bu modül için bir kayıt var mı?
          const record: any = completedLessons.find(
            (r: any) => r.id == this.lesson.id
          );

          if (!record) {
            // Yoksa yeni kayıt ekle
            completedLessons.push({
              id: this.lesson.id,
              progress: value,
              bookmark: { h: 0 }
            });

            // Veriyi güncelle
            await regRef.update({
              completedLessons: completedLessons
            });
          }
        }
      }
    }
  }
});
